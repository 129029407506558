import * as React from "react";
import SkillData from "../../Database/Skills.json";
import { Box, Grid2, Typography } from "@mui/material";
import { teal } from "@mui/material/colors";

let useFilterSkills = function (skillCategory) {
  return SkillData.filter((skill) => skill.category === skillCategory);
};

const style1 = { backgroundColor: teal[50], p: 2 };

export default function Skill() {
  const techicalSkills = useFilterSkills("Technical Skills");
  const softSkills = useFilterSkills("Soft Skills");
  const languages = useFilterSkills("Language");

  return (
    <Grid2 container spacing={1} size={{ xs: 12 }}>
      <Grid2
        size={{ xs: 12}}
        sx={{ borderBottom: `2px solid ${teal[900]}` }}
      >
        <Typography
          variant={"h5"}
          component={"h2"}
          sx={{ fontWeight: "500" }}
          p={1}
          pl={2}
        >
          Skills
        </Typography>
      </Grid2>
      <Grid2 size={{ xs: 12, md: 4 }} sx={style1}>
        <Typography variant='h6' component={"h3"} fontWeight={500}>
          Techical Skills
        </Typography>
        <Box component='ul'>
          {techicalSkills.map((skill) => (
            <Typography
              variant='subtitle1'
              component='li'
              key={skill.id}
              title={skill.name}
            >
              {skill.name}
            </Typography>
          ))}
        </Box>
      </Grid2>
      <Grid2 size={{ xs: 12, md: 4 }} sx={style1}>
        <Typography variant='h6' component={"h3"} fontWeight={500}>
          Soft Skills
        </Typography>
        <Box component='ul'>
          {softSkills.map((skill) => (
            <Typography
              variant='subtitle1'
              component='li'
              key={skill.id}
              title={skill.name}
            >
              {skill.name}
            </Typography>
          ))}
        </Box>
      </Grid2>
      <Grid2 size={{ xs: 12, md: 4 }} sx={style1}>
        <Typography variant='h6' component={"h3"} fontWeight={500}>
          Language
        </Typography>
        <Box component='ul'>
          {languages.map((skill) => (
            <Typography
              variant='subtitle1'
              component='li'
              key={skill.id}
              title={skill.name}
            >
              {skill.name}
            </Typography>
          ))}
        </Box>
      </Grid2>
    </Grid2>
  );
}
