import { Grid2, Paper, Typography } from "@mui/material";
import { teal } from "@mui/material/colors";
import React from "react";
import CertificationData from "../../Database/Certification.json";

export default function Certification() {
  return (
    <Grid2 container spacing={1} size={{ xs: 12 }}>
      <Grid2 size={{ xs: 12 }} borderBottom={`2px solid ${teal[900]}`}>
        <Typography variant='h5' component='h2' color={teal[900]} p={1} pl={2}>
          Certifications
        </Typography>
      </Grid2>
      {CertificationData.sort((a,b)=> new Date(b.issueDate) - new Date(a.issueDate)).map((item) => {
        return (
          <Grid2 size={{ xs: 12 }} key={item.id}>
            <Paper sx={{ backgroundColor: teal[50], p: 2 }}>
              <Typography variant='body2'>{item.issueDate}</Typography>
              <Typography variant='h6' component='h3'>
                {item.title}
              </Typography>
              <Typography variant='body1'>{item.companyName}</Typography>
              <Typography variant="body2">{item.mode}</Typography>
              <Typography variant="body2">- {item.skills.join(", ")}</Typography>
            </Paper>
          </Grid2>
        );
      })}
    </Grid2>
  );
}
