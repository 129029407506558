import QualificationData from "../../Database/Qualification.json";
import { teal } from "@mui/material/colors";
import {
  Box,
  Typography,
  Card,
  CardMedia,
  CardContent,
  Grid2,
  createTheme,
  ThemeProvider,
} from "@mui/material";

const theme = createTheme();

export default function Qualification() {
  return (
    <ThemeProvider theme={theme}>
      <Grid2 container spacing={1} size={{ xs: 12 }}>
        <Grid2 size={{ xs: 12 }} borderBottom={`2px solid ${teal[700]}`}>
          <Typography
            variant='h5'
            component='h2'
            color={teal[900]}
            p={1}
            pl={2}
            fontWeight={500}
          >
            Qualifications
          </Typography>
        </Grid2>
        <Grid2 container size={{ xs: 12 }}>
          {QualificationData.map((data, index) => (
            <Grid2 size={{ xs: 12 }} key={data.id}>
              <Card sx={{ padding: "2 0" }} >
                <Box
                  p={2}
                  pl={2}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    [theme.breakpoints.down("md")]: {
                      flexDirection: "column",
                    },
                    [theme.breakpoints.up("md")]: {
                      flexDirection: index % 2 ? "row" : "row-reverse",
                    },
                  }}
                  bgcolor={teal[50]}
                >
                  <Box
                    p={1}
                    sx={{
                      [theme.breakpoints.down("md")]: {
                        width: "100%",
                      },
                      [theme.breakpoints.up("md")]: {
                        width: 130,
                      },
                      aspectRatio: "1/1",
                      backgroundColor: "#ffffff",
                    }}
                    borderRadius={2}
                  >
                    <CardMedia
                      component={"img"}
                      image={data.logo}
                      alt={data.logo}
                    />
                  </Box>
                  <CardContent
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignSelf: "self-start",
                      [theme.breakpoints.down("md")]: {
                        width: "100%",
                      },
                      [theme.breakpoints.up("md")]: {
                        width: "calc(100% - 130px)",
                      },
                    }}
                  >
                    <Typography variant='h6' component='h3'>
                      {data.course}
                    </Typography>
                    <Typography
                      variant={"body1"}
                      component={"h4"}
                      fontWeight={400}
                    >
                      {data.elective}
                    </Typography>
                    <Typography variant={"body2"}>
                      {data["school&college"]}
                    </Typography>
                    <Typography variant={"body2"}>{data.Affiliated}</Typography>
                    <Typography
                      variant={"body2"}
                    >{`${data.startDate} - ${data.endDate}`}</Typography>
                  </CardContent>
                </Box>
              </Card>
            </Grid2>
          ))}
        </Grid2>
      </Grid2>
    </ThemeProvider>
  );
}
