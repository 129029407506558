import React from "react";
import ExperienceData from "../../Database/Experiences.json";
import { Box, Grid2, Paper, Typography } from "@mui/material";
import { teal } from "@mui/material/colors";

export default function Experience() {
  return (
    <Grid2 container spacing={1} size={{ xs: 12 }}>
      <Grid2 size={{xs: 12}} borderBottom={`2px solid ${teal[900]}`}>
        <Typography variant='h5' component='h2' p={1} pl={2} color={teal[900]} >
          Experiences
        </Typography>
      </Grid2>
      {ExperienceData.map((item) => {
        return (
          <Grid2 key={item.id} size={{xs: 12}}>
            <Paper sx={{backgroundColor: teal[50], p:2}}>
              <Box sx={{display:"flex", flexFlow: "row wrap", justifyContent: "space-between", gap:1}}>
                <Typography variant='body1'>
                  {item.jobInternshipTrainee}
                </Typography>
                <Typography variant='body1'>
                  {item.startDate} - {item.endDate}
                </Typography>
              </Box>
              <Typography variant='h6' component='h2'>
                {item.title}
              </Typography>
              <Typography variant='body1'>{item.companyName}</Typography>
              <Typography variant="body1">{item.location}</Typography>
              <Typography variant='body1'>- {item.skills.join(", ")}</Typography>
            </Paper>
          </Grid2>
        );
      })}
    </Grid2>
  );
}
