import "./App.module.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import Skill from "./Components/Skill/Skill";
import Qualification from "./Components/Qualification/Qualification";
import Project from "./Components/Project/Project";
import Profile from "./Components/Profile/Profile";
import { Grid2, Container } from "@mui/material";
import Experience from "./Components/Experience/Experience";
import Certification from "./Components/Certification/Certification";

function App() {
  return (
    <Container sx={{ backgroundColor: "#a6c5bf0f" }} maxWidth='lg'>
      <Grid2 container spacing={2}>
        <Grid2 size={{ xs: 12, sm: 6, md: 4 }}>
          <Profile />
        </Grid2>
        <Grid2 container size={{ xs: 12, sm: 6, md: 8 }}>
          <Skill />
          <Experience />
          <Grid2>
            <Project />
          </Grid2>
          <Certification />
          <Qualification />
        </Grid2>
      </Grid2>
    </Container>
  );
}

export default App;
