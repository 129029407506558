import { Box, Container, Typography } from "@mui/material";
import ProfileData from "../../Database/Profile.json";
import { teal } from "@mui/material/colors";
import {
  Email,
  Facebook,
  GitHub,
  Instagram,
  LinkedIn,
  Phone,
  X,
} from "@mui/icons-material";

const handleSocialIcons = (platform) => {
  switch (platform) {
    case "LinkedIn":
      return <LinkedIn />;
    case "GitHub":
      return <GitHub />;
    case "X":
      return <X />;
    case "Facebook":
      return <Facebook />;
    case "Instagram":
      return <Instagram />;
    default:
      return platform;
  }
};

export default function Profile() {
  return (
    <Box
      sx={{
        backgroundColor: teal[50],
        p: 2,
        m: 1,
        borderRadius: 2,
        position: "sticky",
        top: 15,
      }}
    >
      <Container
        sx={{
          display: "flex",
          alignContent: "center",
          justifyContent: "center",
          width: "80%",
          aspectRatio: "1/1",
          backgroundColor: teal[800],
          border: `6px solid ${teal[800]}`,
          backgroundImage: `url(/images/${ProfileData.image})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          p: 1,
          borderRadius: "50%",
        }}
      ></Container>
      <Box sx={{ textAlign: "center" }}>
        <Typography
          variant='h4'
          component='h1'
          sx={{ fontWeight: "bold", color: teal[900] }}
        >
          {ProfileData.name}
        </Typography>
        <Typography
          variant='h5'
          component='h2'
          sx={{ fontWeight: "bold", color: teal["800"] }}
        >
          {ProfileData.profession}
        </Typography>
      </Box>
      {/* Contact */}
      <Box sx={{ py: 1 }}>
        <Box sx={{ display: "flex", flexFlow: "row nowrap", color: teal[700] }}>
          <Phone />
          <Typography
            variant='body1'
            component='a'
            href={`tel:${ProfileData.phoneNumbers}`}
            sx={{ color: "inherit", pl: 1, textDecoration: "none" }}
          >
            {ProfileData.phoneNumbers}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", flexFlow: "row nowrap", color: teal[700] }}>
          <Email />
          <Typography
            variant='body1'
            component='a'
            href={`mailto:${ProfileData.email}`}
            noWrap={true}
            sx={{ color: "inherit", pl: 1, textDecoration: "none" }}
          >
            {ProfileData.email}
          </Typography>
        </Box>
      </Box>
      {/* Hobbies */}
      <Box py={1}>
        <Typography component='h3' variant='h6' color={teal[800]}>
          Hobbies
        </Typography>
        <Box>
          {ProfileData.hobbies.map((hobby, index) => (
            <Typography component='li' color={teal[700]} key={index}>
              {hobby}
            </Typography>
          ))}
        </Box>
      </Box>
      {/* Address */}
      <Box py={1}>
        <Typography
          component='h3'
          variant='h6'
          color={teal[800]}
          display='inline'
        >
          Address:
        </Typography>
        <Typography
          variant='body1'
          display='inline'
          color={teal[700]}
          pl={1}
        >{`${ProfileData.current_address.line2}, ${ProfileData.current_address.city}, ${ProfileData.current_address.state}, ${ProfileData.current_address.country} - ${ProfileData.current_address.pincode}`}</Typography>
      </Box>
      {/* Social contact */}
      <Box>
        <Typography component='h3' variant='h6' color={teal[800]}>
          Social
        </Typography>
        <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
          {ProfileData.social.map((item, index) => (
            <Typography
              component='a'
              target='_blanck'
              textDecoration='none'
              sx={{
                width: 50,
                height: 50,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                "&:hover": {
                  backgroundColor: "#ffffff",
                  borderRadius: "50%",
                },
              }}
              href={item.url}
              key={index}
              title={item.Platform}
            >
              {handleSocialIcons(item.Platform)}
            </Typography>
          ))}
        </Box>
      </Box>
    </Box>
  );
}
