import ProjectData from "../../Database/Project.json";
import { Close, GitHub } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Collapse,
  Grid2,
  Link,
  Typography,
} from "@mui/material";
import { red, teal } from "@mui/material/colors";
import ImageSlider from "../ImageSlider/ImageSlider";
import { useState } from "react";

export default function Project() {
  const [showImageSlide, setShowImageSlide] = useState(null);
  const [btnLearnMore, setBtnLearnMore] = useState("Learn More");
  const [expanded, setExpanded] = useState(false);

  const handleShowSlide = (prj_id) => {
    setShowImageSlide(
      <ImageSlider prj_id={prj_id} id='ImageSlider'>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: 50,
            height: 50,
            backgroundColor: red[500],
            borderRadius: "50%",
            position: "absolute",
            top: 10,
            right: 20,
            opacity: 0.4,
            zIndex: 100,
            "&:hover": {
              opacity: 1,
            },
          }}
          title='Close'
          onClick={() => setShowImageSlide(null)}
        >
          <Close />
        </Box>
      </ImageSlider>
    );
  };

  const handleLearnMore = () => {
    setExpanded(!expanded);
    setBtnLearnMore(expanded ? "Learn More" : "Close");
  };

  return (
    <Box maxWidth={900} m={1}>
      <Box borderBottom={`2px solid ${teal[700]}`} mb={1}>
        <Typography
          variant='h5'
          component='h2'
          color={teal[900]}
          p={1}
          pl={2}
          fontWeight={500}
        >
          Projects
        </Typography>
      </Box>
      {showImageSlide}
      <Grid2 container spacing={2}>
        {ProjectData.map((project, index) => {
          return (
            <Grid2 size={{ xs: 12, md: 6 }} key={index}>
              <Card
                sx={{ backgroundColor: teal[50] }}
                title={project.prj_title}
              >
                <CardActionArea onClick={() => handleShowSlide(project.prj_id)}>
                  <CardMedia
                    component='img'
                    width='100%'
                    image={`/images/prj/${project.prj_id}/${project.prj_thumbnail}`}
                    alt=''
                    title='click here'
                  />
                </CardActionArea>
                <CardContent>
                  <Typography variant='h6' component='h3'>
                    {project.prj_title}
                  </Typography>
                  <Typography variant='subtitle1' component='h5'>
                    {project.prj_type}
                  </Typography>
                  <Collapse in={expanded} timeout='auto' unmountOnExit>
                    <Box
                      component='ul'
                      display='flex'
                      flexDirection='column'
                      pl={3}
                      mt={1}
                      mb={0}
                    >
                      {project.prj_about.map((item, index) => {
                        return (
                          <Box component='li' key={index}>
                            <Typography
                              variant='body2'
                              color='textSecondary'
                              component='p'
                            >
                              {item}
                            </Typography>
                          </Box>
                        );
                      })}
                      <Box component='li' key={index}>
                        <Typography
                          variant='body1'
                          color='textSecondary'
                          fontWeight={500}
                          component='div'
                        >
                          Technologies:
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            gap: "10px",
                          }}
                        >
                          {project.prj_technologies.map((item, index) => {
                            return (
                              <Typography
                                variant='body2'
                                component='span'
                                key={index}
                                sx={{
                                  backgroundColor: teal[500],
                                  color: teal[50],
                                  p: 0.5,
                                  borderRadius: 1,
                                }}
                              >
                                {item}
                              </Typography>
                            );
                          })}
                        </Box>
                      </Box>
                    </Box>
                  </Collapse>
                </CardContent>
                <CardActions>
                  <Link component='a' target='_blank' href={project.prj_github}>
                    <Button
                      variant='contained'
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: 1,
                        px: 1,
                      }}
                      size='small'
                    >
                      <GitHub />
                      View Code
                    </Button>
                  </Link>
                  <Button
                    size='small'
                    expand={expanded}
                    onClick={handleLearnMore}
                    aria-expanded={expanded}
                    aria-label='show more'
                  >
                    {btnLearnMore}
                  </Button>
                </CardActions>
              </Card>
            </Grid2>
          );
        })}
      </Grid2>
    </Box>
  );
}
